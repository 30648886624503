
import mixins from 'vue-typed-mixins';

import DepositData from 'Modules/Payment/components/Deposit/Deposit.Data.vue';
import WalletsApi from 'Apis/Wallets';
import CancelDepositRequestData from 'Entities/walletExecutor/CancelDepositRequestData';
import ApiError from 'Entities/ApiError';
import UserTransferAddress from 'Entities/privatePresenter/UserTransferAddress';
import WalletAddressResponseData from 'Entities/walletExecutor/WalletAddressResponseData';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import AssetValue from 'UI/AssetValue.vue';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import QrCode from 'UI/QrCode.vue';
import { formCryptoQr } from 'Lib/utils/formCryptoQr';

type Data = {
    showQr: boolean;
    showWhitelistedWalletDetails: boolean;
    previousRoute: string;
};

type Methods = {
    copy: (text: string | number | undefined, field: string | undefined) => void;
    cancelTransferAndHideModal: (data: string) => void;
    toggleQr: () => void;
    toggleDetails: () => void;
    calculateQrTop: () => void;
};

type Computed = {
    qrValue: string;
};

export default mixins(DepositData).extend<Data, Methods, Computed>({
    mixins: [DepositData],
    components: {
        QrCode,
        Icon,
        Button,
        AssetValue,
    },
    data() {
        return {
            showQr: false,
            previousRoute: '/wallets',
            showWhitelistedWalletDetails: true,
        };
    },
    computed: {
        qrValue() {
            if (!this.depositUi.destinationAddress) {
                return '';
            }

            return formCryptoQr({
                network: this.depositUi.blockchain,
                amount: this.depositUi.amount,
                address: this.depositUi.destinationAddress.address,
            });
        },
    },
    methods: {
        toggleDetails() {
            this.showWhitelistedWalletDetails = !this.showWhitelistedWalletDetails;
            setTimeout(() => {
                this.calculateQrTop();
            }, 300);
        },
        calculateQrTop() {
            const topMargin = 72;
            const goBackHeight = 14;
            const goBackMargin = 15;
            const headerHeight = 54;

            const rects = (this.$refs.qrButton as any).getBoundingClientRect();
            this.$nextTick(() => {
                (this.$refs.qr as any).$el.style.top = `
                    ${rects.y
                        - topMargin
                        - goBackMargin
                        - goBackHeight
                        - headerHeight
                        + (rects.height / 2)}px
                `;
            });
        },
        toggleQr() {
            this.calculateQrTop();
            this.showQr = !this.showQr;
        },
        copy(text, fieldName) {
            if (text && fieldName) {
                copyTextWithPermissions(String(text), this.$store.dispatch, fieldName);
            }
        },
        async cancelTransferAndHideModal(transferId) {
            try {
                await WalletsApi.cancelDeposit(new CancelDepositRequestData({
                    transferId,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during cancelling deposit');
                }
            } finally {
                await this.$router.push(this.previousRoute);
            }
        },
    },
    async mounted() {
        const { depositUi, address, destinationAddress, previousRoute } = this.$route.query;
        this.depositUi = { ...depositUi, address: new UserTransferAddress(address), destinationAddress: new WalletAddressResponseData(destinationAddress) };
        this.previousRoute = previousRoute;
    },
});
